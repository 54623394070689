import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h5 {
    color: var(--lightest-slate);
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

  .Typist {
    color: var(--lightest-slate);
    text-align: center;
    font-size: 73px;
    margin-top: -30px;
  }
  .Typist .Cursor--blinking {
    color: var(--green);
    padding-left: 4px;
  }

  .intro-title {
    margin: 0;
    font-size: clamp(40px, 8vw, 70px);
    font-weight: 500;
  }
  .intro-name {
    color: var(--green);
    margin: 0;
    font-size: clamp(40px, 8vw, 70px);
    font-weight: 800;
  }

  .intro-subtitle {
    margin-top: -34px;
    font-family: 'NTR', sans-serif;
    color: var(--slate);
    font-weight: 500;
    font-size: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const [count, setCount] = useState(1);
  useEffect(() => {
    // document.title = `You clicked ${count} times`;
    // console.log("Count: " + count);
    setCount(1);
  }, [count]);

  // const one = <h1>Hi, my name is</h1>;
  // const two = <h2 className="big-heading">Kevin Nguyen,</h2>;

  const one = (
    <Typist start={500} avgTypingDelay={100} onTypingDone={() => setCount(0)}>
      <span className="intro-name">{'Kevin Nguyen, '}</span>

      <span className="intro-title">CS + Stat @ UBC</span>
      <Typist.Backspace count={15} delay={600} />

      <span className="intro-title">ML Engineer</span>
      <Typist.Backspace count={11} delay={600} />

      {/* <span classNames="intro-title">Stat @ UBC</span>
    <Typist.Backspace count={10} delay={600}/> */}

      <span className="intro-title">Software Engineer</span>
    </Typist>
  );

  // const two = <h3>Welcome to my corner of the internet!</h3>;
  const two = <div className="intro-subtitle">Welcome to my corner of the internet!</div>;

  const three = (
    <div>
      <p>
        I'm recent graduate of the University of British Columbia and a software engineer. This website is intended as a
        fun project for me to improve on my front-end development abilities, as well as a place for
        me to showcase different projects that I've worked on.
        <br></br>
        <br></br>
        {/* I also hope for it to be a place for me to document and reflect upon my time so far as both a student as UBC and an individual.  */}
        Glad to have you here 😊
      </p>
    </div>
  );

  const four = (
    <a className="email-link" href="mailto:kn2001@student.ubc.ca" target="_blank" rel="noreferrer">
      Get in touch!
    </a>
  );

  // const four = (
  //   <a className="email-link" href="https://7rf6tw-5173.csb.app/" target="_blank" rel="noopener noreferrer">
  //     For Sandra 💚
  //   </a>
  // );

  const items = [one, two, three, four];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
